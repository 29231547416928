import { API_URL } from '../../../app-config/constants';
import { get, post } from '../request';
const BASE_URL = `${API_URL}/summary-assistant`;
export function getPromptResult(id, type, options) {
    return post(BASE_URL, { postData: Object.assign({ type, id }, options) });
}
regenerate.operation = 'COMMAND';
export function regenerate(keyTakeawayId, summaryResponse) {
    return post(`${BASE_URL}/regenerate/${keyTakeawayId}`, { postData: summaryResponse });
}
getSummary.operation = 'READ';
export function getSummary(id) {
    return get(`${BASE_URL}/${id}`);
}
save.operation = 'COMMAND';
export function save(summaryResponse) {
    return post(`${BASE_URL}/save`, { postData: summaryResponse });
}
export function getQuotaTokens() {
    return get(`${BASE_URL}/tokens`);
}
